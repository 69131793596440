import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './Button.scss'

const Button = ({ children, color, size, className, ...props }) => {
  const btnClassName = classNames(
    'button',
    `button--${color}`,
    `button--${size}`,
    {
      [className]: className,
    }
  )

  return (
    <button className={btnClassName} {...props}>
      {children}
    </button>
  )
}

Button.defaultProps = {
  color: 'accent',
  size: 'med',
}

Button.propTypes = {
  color: PropTypes.oneOf(['main', 'accent', 'accent2', 'ghost', 'white']),
  size: PropTypes.oneOf(['small', 'med', 'large']),
  className: PropTypes.string,
}

export default Button
