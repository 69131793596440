import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import './Link.scss'

const Link = ({ to, children, ...props }) => (
  <GatsbyLink to={to} className="Link" {...props}>
    {children}
  </GatsbyLink>
)

Link.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
}

export default Link
