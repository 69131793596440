import React, { useState, useCallback, useEffect } from 'react'
import Link from './Link'
import Logo from './Logo'
import Button from './Button'
import { Menu, Home, ThumbsUp, Picture, Message, Phone, Close } from './Icons'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import './Header.scss'

export const LINKS = [
  { label: 'Home', to: '/', icon: Home },
  { label: 'Projects', to: '/projects', icon: Picture },
  { label: 'About Us', to: '/about', icon: ThumbsUp },
  { label: 'Contact', to: '/contact', icon: Message },
]

const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false)

  const toggleMenu = useCallback(() => {
    setIsMenuActive(!isMenuActive)
  }, [isMenuActive])

  const mobileMenuClassName = classNames('mobileMenu', {
    'mobileMenu--active': isMenuActive,
    'mobileMenu--disabled': !isMenuActive,
  })

  useEffect(() => {
    document.body.style.opacity = 1
  }, [])

  const listVariants = {
    hidden: {
      opacity: 0,
      scale: 0.9,
      x: 30,
      y: -30,
      transition: {
        ease: 'easeInOut',
        duration: 0.25,
      },
    },
    visible: {
      opacity: 1,
      scale: 1,
      x: 10,
      y: -10,
      transition: {
        ease: 'easeInOut',
        duration: 0.25,
      },
    },
  }

  const background = {
    disabled: {
      opacity: 0,
    },
    active: {
      opacity: 1,
    },
  }

  const backgroundClassName = classNames('background', {
    'background--active': isMenuActive,
    'background--disabled': !isMenuActive,
  })

  return (
    <header>
      <Link to="/">
        <h1>
          <Logo />
          AGHicks Building Services
        </h1>
      </Link>
      <nav>
        <motion.div
          className={backgroundClassName}
          initial={background.disabled}
          animate={isMenuActive ? background.active : background.disabled}
          aria-hidden
          onClick={toggleMenu}
        />
        <button className="mobileMenuButton" onClick={toggleMenu}>
          menu <Menu />
        </button>
        <motion.ul
          className={mobileMenuClassName}
          variants={listVariants}
          initial="hidden"
          animate={isMenuActive ? 'visible' : 'hidden'}
        >
          <button onClick={toggleMenu} className="mobileMenu_closeButton">
            close
            <Close />
          </button>
          {LINKS.map(LINK => (
            <li className="mobileMenu_item" key={LINK.label}>
              <Link
                to={LINK.to}
                className="mobileMenu_label"
                onClick={toggleMenu}
              >
                <LINK.icon />
                {LINK.label}
              </Link>
            </li>
          ))}
          <div className="mobileMenu_contactButtons">
            <a href="tel:07710537685">
              <Button color="accent">
                <Phone />
                Call us
              </Button>
            </a>
            <Link to="/contact">
              <Button color="accent2" onClick={toggleMenu}>
                <Message />
                Message us
              </Button>
            </Link>
          </div>
        </motion.ul>
        <ul className="linkList">
          {LINKS.map(LINK => (
            <li className="linkList_item" key={LINK.label}>
              <Link to={LINK.to} className="linkList_label">
                {LINK.label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}

export default Header
