import React from 'react'
import { LINKS } from './Header'
import { Link } from 'gatsby'
import './Footer.scss'

const Footer = () => (
  <footer>
    <nav>
      <ul className="footer_list footer_pages">
        <h5>Pages</h5>
        {LINKS.map(LINK => (
          <li className="footer_listItem" key={LINK.label}>
            <Link to={LINK.to} className="footer_listLink">
              {LINK.label}
            </Link>
          </li>
        ))}
      </ul>
      <ul className="footer_list footer_contact">
        <h5>Contact</h5>
        <li className="footer_listItem">
          <a href="tel:07710537685" className="footer_listLink">
            07710 537 685
          </a>
        </li>
        <li className="footer_listItem">
          <a href="mailto:alan.g.hicks@icloud.com" className="footer_listLink">
            alan.g.hicks@icloud.com
          </a>
        </li>
      </ul>
      <div className="footer_list footer_address">
        <h5>Address</h5>
        19 Bentley Close
        <br />
        Rectory Farm
        <br />
        Northampton
        <br />
        Northamptonshire
        <br />
        NN3 5JS
      </div>
    </nav>
  </footer>
)

export default Footer
