module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-34741189-1","head":true,"respectDNT":true,"defer":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AGHicks Building Services","short_name":"AGHicks","start_url":"/","background_color":"white","theme_color":"#ffb538","display":"minimal-ui","icon":"src/images/logo.svg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
