import React from 'react'
import PropTypes from 'prop-types'
import Header from './Header'
import Footer from './Footer'
import { motion, AnimatePresence } from 'framer-motion'
import { TRANSITION_DURATION } from '../../constants'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Layout.scss'

const Layout = ({ children, location }) => {
  const variants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: TRANSITION_DURATION,
        delay: TRANSITION_DURATION,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: TRANSITION_DURATION },
    },
  }
  return (
    <div className="Layout">
      <Header />
      <AnimatePresence>
        <motion.main
          key={location.pathname}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
          className="Layout_content"
        >
          <div className="Layout_mainWrap">{children}</div>
          <Footer />
        </motion.main>
      </AnimatePresence>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
