import React from 'react'

const Logo = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="50" fill="#FFB538" />
    <path d="M16.3901 41.7227L21.7234 36.3894C21.974 36.1388 22.3128 36 22.6674 36H24.0006V34.6666C24.0006 34.312 24.142 33.9733 24.39 33.7227L35.0565 23.0562C37.1951 20.9177 40.7791 17.3337 48.0004 17.3337C55.2163 17.3337 58.5256 20.6431 60.9442 23.0563C61.3256 23.4377 61.4402 24.0109 61.2322 24.5096C61.0268 25.0083 60.5389 25.3336 60.0002 25.3336C49.2457 25.3336 48.0004 29.899 48.0004 33.3335C48.0004 36.1869 49.6164 38.3601 50.6483 39.4668L41.9364 48.1787C39.5791 46.5841 36.2299 44.7014 34.6672 44.8402V46.6668C34.6672 47.0214 34.5258 47.3601 34.2778 47.6107L27.6112 54.2773C27.3606 54.5253 27.0219 54.6667 26.6672 54.6667H26.6219C26.2512 54.656 25.9045 54.4907 25.6645 54.2133L16.3312 43.5468C15.8674 43.0161 15.8941 42.2214 16.3901 41.7227Z" fill="white" />
    <path d="M77.984 66.7652C79.2826 68.0638 80 69.7917 80 71.7863C80 75.5809 76.9147 78.6663 73.1201 78.6663C71.1867 78.6663 69.3307 77.8449 68.0295 76.4157L43.955 49.9334L52.5523 41.3335L77.984 66.7652Z" fill="white" />
  </svg>
)

export default Logo